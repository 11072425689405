import React from 'react';
import { Question, Answer } from '../types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LineBreakTypography from './LineBreakTypography';

interface QuestionItemProps {
  question: Question;
  answer: Answer | undefined;
  onAnswer: (questionId: number, selectionNumber: number, selectionText: string) => void;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ question, answer, onAnswer }) => (
  <Box 
    className="question-item"
    id={`question-${question.id}`}
    sx={{ 
      borderBottom: '2px solid #e0e0e0', 
      pb: 2,
      mb: 2,
    }}
  >
    <LineBreakTypography
      text={question.title}
      variant="h6"
      color="primary"
      gutterBottom
    />
    <LineBreakTypography
      text={question.text}
      variant="body1"
      sx={{ mb: 2 }}
    />
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {question.options.map(option => (
        <Button
          key={option.id}
          variant={answer?.selectionNumber === option.selectionNumber ? "contained" : "outlined"}
          onClick={() => onAnswer(question.id, option.selectionNumber, option.text)}
          size="medium"
          sx={{
            minWidth: '120px',
            width: '100%', // ボタンを親要素の幅いっぱいに広げる
            maxWidth: '300px', // 最大幅を設定（必要に応じて調整）
            justifyContent: 'flex-start', // ボタン内のコンテンツを左寄せ
            textAlign: 'left',
            padding: '8px 16px', // 左右のパディングを調整
            transition: 'all 0.3s',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 2,
            },
          }}
        >
          {option.text}
        </Button>
      ))}
    </Box>
  </Box>
);

export default QuestionItem;