import React, { useState, useEffect, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface ExitIntentPopupProps {
  title?: string;
  children: ReactNode;
  onClose: () => void;
  onAction?: () => void;
  actionText?: string;
  mobileTimeout?: number;
  scrollThreshold?: number;
  cooldownPeriod?: number;
}

const ExitIntentPopup: React.FC<ExitIntentPopupProps> = ({ 
  title = 'お待ちください！', 
  children, 
  onClose, 
  onAction, 
  actionText = '詳細を見る',
  mobileTimeout = 30000,
  scrollThreshold = 500,
  cooldownPeriod = 60000 // デフォルトは1分
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [lastPopupTime, setLastPopupTime] = useState(0);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      const timer = setTimeout(() => {
        showPopupIfAllowed();
      }, mobileTimeout);

      return () => clearTimeout(timer);
    } else {
      const handleMouseLeave = (e: MouseEvent) => {
        if (e.clientY <= 0) {
          showPopupIfAllowed();
        }
      };

      document.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        document.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [isMobile, mobileTimeout]);

//   useEffect(() => {
//     if (isMobile) {
//       let lastScrollTop = 0;
//       let scrollDistance = 0;

//       const handleScroll = () => {
//         const st = window.pageYOffset || document.documentElement.scrollTop;
//         if (st < lastScrollTop) {
//           // 上にスクロールしている
//           scrollDistance += (lastScrollTop - st);
//           if (scrollDistance > scrollThreshold) {
//             showPopupIfAllowed();
//             scrollDistance = 0; // リセット
//           }
//         } else {
//           // 下にスクロールしている、または変化なし
//           scrollDistance = 0; // リセット
//         }
//         lastScrollTop = st <= 0 ? 0 : st;
//       };

//       window.addEventListener('scroll', handleScroll);

//       return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }
//   }, [isMobile, scrollThreshold]);

  const showPopupIfAllowed = () => {
    const currentTime = Date.now();
    if (currentTime - lastPopupTime > cooldownPeriod) {
      setIsOpen(true);
      setLastPopupTime(currentTime);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>閉じる</Button>
        {onAction && (
          <Button onClick={handleAction} variant="contained" color="primary">
            {actionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExitIntentPopup;