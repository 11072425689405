import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ResultOverview from '../components/ResultOverview';
import SectionResult from '../components/SectionResult';
import { fetchResult } from '../data';
import { PreQuestionAnswer, Result } from '../types';
import { Button, Paper } from '@mui/material';
import Banner from '../components/Banner';
import ExitIntentPopup from '../components/ExitIntentPopup';
import { trackBunnerClick } from '../firebase';

const ResultPage: React.FC = () => {
  const [result, setResult] = useState<Result | null>(null);
  const [demographics, setDemographics] = useState<PreQuestionAnswer | null>(null);
  const navigate = useNavigate();
  const bannerAltText = "＼再登校を目指す親御さんはこちら／";

  const situationLabel = () => {
    if (demographics && demographics.situation === '不登校') {
      return 'futoko';
    } else if (demographics && demographics.situation === '通学中') {
      return 'kosodate';
    } else {
      return 'futoko';
    }
  }

  const bannerLink = () => {
    if (demographics && demographics.situation === '不登校') {
      return 'https://sudachi.support/lp/3weeks/?utm_source=futoukou_shindan&utm_medium=other&utm_campaign=futoukou_shindan';
    } else if (demographics && demographics.situation === '通学中') {
      return 'https://sudachi.support/lp/kosodate/?utm_source=futoukou_shindan&utm_medium=other&utm_campaign=futoukou_shindan';
    } else {
      return 'https://sudachi.support/lp/3weeks/?utm_source=futoukou_shindan&utm_medium=other&utm_campaign=futoukou_shindan';
    }
  }

  const bannerText = () => {
    if (demographics && demographics.situation === '不登校') {
      return '再登校に向けたサポート情報をご用意しています。今すぐチェックしませんか？';
    } else if (demographics && demographics.situation === '通学中') {
      return '子育ての悩み。手遅れになる前に根本解決しませんか？';
    } else {
      return '再登校に向けたサポート情報をご用意しています。今すぐチェックしませんか？';
    }
  }

  useEffect(() => {
    const savedResult = localStorage.getItem('diagnosisResult');
    const savedDemographics = localStorage.getItem('demographics');
    if (savedResult && savedDemographics) {
      setResult(JSON.parse(savedResult));
      setDemographics(JSON.parse(savedDemographics));
    } else {
      console.log("データが見つかりませんでした");
      navigate('/');
    }
  }, [navigate]);

  if (!result) {
    return (
      <Container maxWidth="md">
        {/* <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
            診断結果が見つかりません
          </Typography>
        </Box> */}
      </Container>
    );
  }

  const handlePopupClose = () => {
    console.log('Popup closed');
  };

  const handlePopupAction = () => {
    window.open(bannerLink(), '_blank');
  };

  return (
    <>
    <Container maxWidth="md">
      <Box sx={{ mt: 2 }}>
      <Banner 
          imageUrl="/img/header.png" 
          altText="ヘッダー"
          maxWidth="100%"
          maxHeight="100%"
          animate={false}  // アニメーションを有効化
        />
        {result ? (
          <Box>
            <ResultOverview result={result} />
          </Box>
        ) : (
          <Typography variant="body1" align="center">
            結果を読み込んでいます...
          </Typography>
        )}
      </Box>
      <ExitIntentPopup 
        title="お待ちください！"
        onClose={handlePopupClose}
        onAction={handlePopupAction}
        actionText="詳細を見る"
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography gutterBottom>
            {bannerText()}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Banner 
              imageUrl={`/img/${situationLabel()}-support-banner.png`}
              altText={bannerAltText}
              maxWidth="100%"
              maxHeight="200px"
              link={bannerLink()}
              onClick={() => trackBunnerClick()}
            />
          </Box>
        </Box>
      </ExitIntentPopup>
    </Container>
    <Box sx={{ mt: 2, mb: 1 }}> {/* バナーの上下にマージンを追加 */}
      <Banner 
        imageUrl={`/img/appeal-to-${situationLabel()}-support.png`}
        altText="サポートのご案内"
        maxWidth="100%"
        maxHeight="100%"
        animate={false}  // アニメーションを有効化
      />
      <Banner 
        imageUrl={`/img/${situationLabel()}-support-cv.png`}
        altText="詳細はこちら"
        maxWidth="100%"
        maxHeight="300px"
        link={bannerLink()}
        animate={false}  // アニメーションを有効化
        onClick={() => trackBunnerClick()}
      />
    </Box>

    <Box>
      {result && result.sectionResults.map(sectionResult => (
        <SectionResult key={sectionResult.section.id} sectionResult={sectionResult} />
      ))}
    </Box>
    <Box sx={{ mt: 2, margin: 3 }}>
        <Banner 
          imageUrl={`/img/${situationLabel()}-support-banner.png`}
          altText="バナー画像の説明"
          maxWidth="100%"
          maxHeight="300px"
          link={bannerLink()}
          animate={true}  // アニメーションを有効化
          onClick={() => trackBunnerClick()}
        />
    </Box>
    </>
  );
};

export default ResultPage;